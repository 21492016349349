import React, { useState, useEffect, useRef } from "react";
import KeywordsModel from "../../Models/Keywords";
import { GET_DICTIONARIES } from "../../Models/Keywords/urls";
import BonfireAPI from "../Data/Http";
import useUser from "./useUser";

export default function useSessionInfoForm(sessionId?: string) {
  const [speakingLanguage, setSpeakingLanguage] = useState<
    { value: string; label: string }[]
  >([{ value: "en-US", label: "English" }]);
  const [dictionaryId, setDictionaryId] = useState<string>();
  const [sessionType, setSessionType] = useState("local");
  const [sessionAccessType, setSessionAccessType] = useState("public");
  const [sessionName, setSessionName] = useState("");
  const [sessionDescription, setSessionDescription] = useState("");
  const [dictionaries, setDictionaries] = useState<KeywordsModel[]>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [captionLanguages, setCaptionLanguages] = useState<
    { value: string; label: string }[]
  >([]);
  const [translateMode, setTranslateMode] = useState(false);
  const [autoClean, setAutoClean] = useState<boolean>(false);
  const [consumePerMinute, setconsumePerMinute] = useState<number>(1);
  const [speechLanguages, setSpeechLanguages] = useState<
    { value: string; label: string }[]
  >([]);
  const [speechToSpeechMode, setSpeechToSpeechMode] = useState<boolean>(false);
  const [moveSessionDataToQa, setMoveSessionDataToQa] = useState<boolean>(false);

  const { user } = useUser();
  const selectedOrganizationId = useRef<string>("");

  useEffect(() => {
    try {
      getDictionaries();

      if (sessionId) {
        getSessionDetails();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsPageLoading(false);
    }
  }, [sessionId]);

  // Hook to update data when the user changes the organization
  useEffect(() => {
    if (selectedOrganizationId.current && selectedOrganizationId.current != user.selectedOrganizationId) {
      getDictionaries();
    }
    selectedOrganizationId.current = user.selectedOrganizationId;
  }, [user.selectedOrganizationId]);

  useEffect(() => {
    setconsumePerMinute(calculateConsumePerMinute());
  }, [speakingLanguage, captionLanguages, speechLanguages]);

  useEffect(() => {
    hasOrganizationEnabledDataMove();
  }, [selectedOrganizationId.current]);

  const getSessionDetails = async () => {
    try {
      const data = await BonfireAPI.get(`/sessions/session/${sessionId}`, null);
      setSessionName(data.name);
      setDictionaryId(data.dictionaryIds);
      setSessionAccessType(data.type);
      setSpeakingLanguage(data.speakingLanguage);
      setCaptionLanguages(data.languages);
      setSpeechLanguages(data.speechLanguages);
    } catch (error: any) {
      throw error;
    }
  };

  const hasOrganizationEnabledDataMove = async () => {
    const data = await BonfireAPI.get(`/users/me/selectedOrganization/${selectedOrganizationId.current}`, null);

    if (data && data.moveSessionDataToQa !== undefined) {
      setMoveSessionDataToQa(data.moveSessionDataToQa);
    }
  }

  const getDictionaries = async () => {
    try {
      const dictionariesList = await BonfireAPI.requestArray(
        GET_DICTIONARIES,
        KeywordsModel
      );
      setDictionaries(dictionariesList as KeywordsModel[]);
    } catch (error) {
      throw error;
    }
  };

  const onChangeSessionType = (value: any) => {
    setSessionType(value);
  };

  const onChangeSessionAccessType = (value: any) => {
    setSessionAccessType(value);
  };

  const onChangeSessionName = (value: string) => {
    setSessionName(value);
  };

  const onChangeSessionDescription = (value: string) => {
    setSessionDescription(value);
  };

  const onChangeCaptionLanguages = (
    languages: { value: string; label: string }[]
  ) => {
    setCaptionLanguages(languages);
    const deletedElementsForSpeech = captionLanguages.filter((item: any) => !languages.includes(item));
    if (deletedElementsForSpeech.length > 0) {
      setSpeechLanguages((prevState) => prevState.filter((item: any) => !deletedElementsForSpeech.some(caption => caption.label === item.label)));
    }
  };

  const onChangeSpeechLanguages = (
    languages: { value: string; label: string }[]
  ) => {
    setSpeechLanguages(languages);
    const addedElements = languages.filter((item: any) => !speechLanguages.includes(item) && !captionLanguages.some(caption => caption.label === item.label));
    if (addedElements.length > 0) {
      setCaptionLanguages((prevState) => [...prevState, ...addedElements]);
    }
  };

  const onChangeDictionary = (value: any) => {
    setDictionaryId(value);
  };

  const onChangeSpeakingLanguage = (
    languages: { value: string; label: string }[]
  ) => {
    setSpeakingLanguage(languages);
    if (translateMode) {
      const addedElements = languages.filter((item: any) => !speakingLanguage.includes(item) && !captionLanguages.some(caption => caption.label === item.label));
      if (addedElements.length > 0) {
        setCaptionLanguages((prevState) => [...prevState, ...addedElements]);
      }

      const deletedElements = speakingLanguage.filter((item: any) => !languages.includes(item));
      if (deletedElements.length > 0) {
        setCaptionLanguages((prevState) => prevState.filter((item: any) => !deletedElements.some(caption => caption.label === item.label)));
        setSpeechLanguages((prevState) => prevState.filter((item: any) => !deletedElements.some(caption => caption.label === item.label)));
      }
    };
  }

  const onToggleTranslateMode = () => {
    setTranslateMode(!translateMode);
    speechToSpeechMode === true && setSpeechToSpeechMode(!translateMode)
    translateMode ? setCaptionLanguages([]) : setCaptionLanguages(speakingLanguage);
  };

  const onToggleSaveToProfileMode = () => {
    setAutoClean(!autoClean);
  };

  const onToggleSpeechToSpeechMode = () => {
    setSpeechToSpeechMode(!speechToSpeechMode);
    translateMode === false && setTranslateMode(!speechToSpeechMode)
    !translateMode && speechToSpeechMode ? setCaptionLanguages([]) : setCaptionLanguages(speakingLanguage);
    setSpeechLanguages([])
    //speechToSpeechMode ? setSpeechLanguages([]) : setSpeechLanguages(speakingLanguage);
  }

  const calculateConsumePerMinute = () => {
    // Commented code is the logic suggested by Tadas.
    // length = speakingLanguage.length;

    // if(!translateMode) return length;

    // for (let i = 0; i < speakingLanguage.length; i++) {
    //   for (let j = 0; j < captionLanguages.length; j++) {
    //     if (speakingLanguage[i] !== captionLanguages[j]) {
    //       length++;

    //     }
    //   }
    // }
    // return length;

    // The logic below is the one that is similarly implemented in the backend and suggested by Nikolai
    let speakingLanguagesLength = speakingLanguage.length;
    let captionLanguagesLength = captionLanguages.length;
    let speechLanguagesLength = speechLanguages.length;

    if (speakingLanguage.length === 1 && captionLanguages.length === 1) {
      return 1 + speechLanguagesLength;
    }

    return speakingLanguagesLength + captionLanguagesLength + speechLanguagesLength;
  }

  return {
    speakingLanguage,
    dictionaryId,
    sessionType,
    sessionAccessType,
    sessionName,
    sessionDescription,
    dictionaries,
    isPageLoading,
    captionLanguages,
    translateMode,
    autoClean,
    consumePerMinute,
    speechLanguages,
    speechToSpeechMode,
    onChangeSpeechLanguages,
    onChangeSessionType,
    onChangeSessionAccessType,
    onChangeSessionName,
    onChangeSessionDescription,
    onChangeCaptionLanguages,
    onChangeDictionary,
    onChangeSpeakingLanguage,
    onToggleTranslateMode,
    onToggleSaveToProfileMode,
    onToggleSpeechToSpeechMode,
    moveSessionDataToQa
  };
}
